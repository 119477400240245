// import React, { useState,useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// import styled from 'styled-components';



// const FooterWrapper = styled.footer`
//   background-color: rgba(240, 241, 241, 1);
//   z-index: 0;
//   display: flex;
//   width: 100%;
//   padding-top: 50px;
//   flex-direction: column;
//   color: rgba(109, 82, 58, 1);
//   text-transform: uppercase;
//   justify-content: start;
//   font: 700 34px/1 Open Sans, sans-serif;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const Title = styled.h2`
//   align-self: start;
//   padding-left: 200px;
//   gap: 10px;
//   @media (max-width: 991px) {
//     padding-left: 20px;
//   }
// `;

// const TeamImage = styled.img`
//   aspect-ratio: 2.88;
//   object-fit: contain;
//   object-position: center;
//   width: 100%;

//   @media (max-width: 991px) {
//     max-width: 100%;
//     margin-top: 40px;
//   }
// `;

// const BottomSection = styled.div`
//   background-color: rgba(255, 255, 255, 1);
//   z-index: 0;
//   display: flex;
//   width: 100%;
//   padding-top: 100px;
//   flex-direction: column;
//   justify-content: center;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const ContentWrapper = styled.div`
//   align-self: center;
//   display: flex;
//   align-items: start;
//   gap: 35px ;
//   justify-content: center;
//   flex-wrap: wrap;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const Column = styled.div`
//   display: flex;
//   min-width: 240px;
//   flex-direction: column;
//   justify-content: start;
// `;

// const ColumnTitle = styled.h3`
// width: 70%;
//   color: rgba(13, 74, 100, 1);
//   text-transform: uppercase;
//   font: 700 34px/48px Open Sans, sans-serif;
// `;

// const Button = styled.button`
//   align-self: start;
//   background-color: rgba(13, 74, 100, 1);
//   margin-top: 32px;
//   min-height: 48px;
//   gap: 8px;
//   color: rgba(255, 255, 255, 1);
//   padding: 14px 48px;
//   font: 500 18px Roboto Flex, sans-serif;
//   border: none;
//   cursor: pointer;
//   @media (max-width: 991px) {
//     padding: 0 20px;
//   }
// `;

// const ContactInfo = styled.div`
//   font-size: 16px;
//   font-weight: 400;
//   line-height: 30px;
//   margin-top: 25px;
// `;

// const SocialLink = styled.a`
//   display: flex;
//   align-items: center;
//   gap: 10px;
//   text-decoration: none;
//   color: inherit;
// `;

// const SocialIcon = styled.img`
//   width: 82px;
//   height: 33px;
// `;

// const FooterStrip = styled.div`
//   background-color: rgba(1, 39, 56, 1);
//   display: flex;
//   min-height: 101px;
//   margin-top: 96px;
//   width: 100%;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     margin-top: 40px;
//   }
// `;

// function Footer() {
//   const navigate = useNavigate();
//   const [scrollToTop, setScrollToTop] = useState(false);
//   const handlePitchClick = () => {
//     setScrollToTop(true);
//   };
//   useEffect(() => {
//     if (scrollToTop) {
//       window.scrollTo(0, 0);
//       navigate('/form');
//       setScrollToTop(false); // Reset state after navigation
//     }
//   }, [scrollToTop, navigate]);
//   const location = "8229 Boone Blvd, Suite 325, Vienna, VA 22182";
//   const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(location)}`;
//   return (
//     <FooterWrapper id="team">
//       <Title>Our Team</Title>
//       <TeamImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f5258789d23421218e8b41fc743a10b56c4c3423602ebfb7565572aa70a11a21?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="Our Team" />
//       <BottomSection>
//         <ContentWrapper>
//           <Column>
//             <ColumnTitle>Unlock Your startup Potential</ColumnTitle>
//             <Button  onClick={handlePitchClick}>PITCH TO US</Button>
//           </Column>
//           <Column>
//             <ColumnTitle>Connect</ColumnTitle>
//             <ContactInfo>
//             <a href="mailto:hi@rdv.studio">hi@rdv.studio</a>

//               <br />
//               <strong>301.306.5102</strong>
//               <br />
//               <br />
//             </ContactInfo>
//             <SocialLink href="https://www.linkedin.com/company/radiant-digital-ventures-studio/"   target="_blank"aria-label="LinkedIn">
//               <SocialIcon loading="lazy" src="./Footer Content.png" alt="LinkedIn" />
           
//             </SocialLink>
//           </Column>
//           <Column>
//             <ColumnTitle>Where to Find Us</ColumnTitle>
//             <ContactInfo>
//               8229 Boone Blvd, Suite 325
//               <br />
//               Vienna, VA 22182
//               <br />
//               <a href={googleMapsUrl}style={{ textDecoration: 'underline', color: 'rgba(13,74,100,1)' }} target="_blank" rel="noopener noreferrer">
//                 View on Map
//               </a>
//             </ContactInfo>
//           </Column>
//         </ContentWrapper>
//         <FooterStrip />
//       </BottomSection>
//     </FooterWrapper>
//   );
// }

// export default Footer;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: rgba(240, 241, 241, 1);
  z-index: 0;
  display: flex;
  width: 100%;
  padding-top: 50px;
  flex-direction: column;
  color: rgba(109, 82, 58, 1);
  text-transform: uppercase;
  justify-content: start;
  font: 700 34px/1 Open Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  align-self: start;
  padding-left: 200px;
  gap: 10px;
  @media (max-width: 991px) {
    padding-left: 20px;
  }
`;

const TeamImageWrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 2.88;
`;

const TeamImage = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;

  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const ClickableArea = styled.div`
  position: absolute;
  cursor: pointer;
  ${({ top, left, width, height }) => `
    top: ${top};
    left: ${left};
    width: ${width};
    height: ${height};
  `}
`;

const BottomSection = styled.div`
  background-color: rgba(255, 255, 255, 1);
  z-index: 0;
  display: flex;
  width: 100%;
  padding-top: 100px;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContentWrapper = styled.div`
  align-self: center;
  display: flex;
  align-items: start;
  gap: 35px ;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Column = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: start;
`;

const ColumnTitle = styled.h3`
  width: 85%;
  color: rgba(13, 74, 100, 1);
  text-transform: uppercase;
  font: 700 20px/32px Inter, sans-serif;
`;

const Button = styled.button`
  align-self: start;
  background-color: rgba(13, 74, 100, 1);
  margin-top: 32px;
  min-height: 48px;
  gap: 8px;
  color: rgba(255, 255, 255, 1);
  padding: 14px 48px;
  font: 500 18px Roboto Flex, sans-serif;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const ContactInfo = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 25px;
  text-transform: Capitalize;
`;

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: inherit;
`;

const SocialIcon = styled.img`
  width: 82px;
  height: 33px;
`;

const FooterStrip = styled.div`
  background-color: rgba(1, 39, 56, 1);
  display: flex;
  min-height: 101px;
  margin-top: 96px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

function Footer() {
  const navigate = useNavigate();
  const [scrollToTop, setScrollToTop] = useState(false);
  
  const handlePitchClick = () => {
    setScrollToTop(true);
  };

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
      navigate('/form');
      setScrollToTop(false); // Reset state after navigation
    }
  }, [scrollToTop, navigate]);

  const location = "8229 Boone Blvd, Suite 325, Vienna, VA 22182";
  const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(location)}`;

  const openLinkedInProfile = (url) => {
    window.open(url, '_blank');
  };

  return (
    <FooterWrapper id="team">
      <Title>Our Team</Title>
      <TeamImageWrapper>
        <TeamImage
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f5258789d23421218e8b41fc743a10b56c4c3423602ebfb7565572aa70a11a21?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66"
          alt="Our Team"
        />
        <ClickableArea
          top="70%"
          left="10%"
          width="20%"
          height="30%"
          onClick={() => openLinkedInProfile('https://www.linkedin.com/in/gtadmor/')}
        />
        <ClickableArea
          top="70%"
          left="40%"
          width="20%"
          height="30%"
          onClick={() => openLinkedInProfile('https://www.linkedin.com/in/dheerajbatra/')}
        />
        <ClickableArea
          top="70%"
          left="70%"
          width="20%"
          height="30%"
          onClick={() => openLinkedInProfile('https://www.linkedin.com/in/vrachakonda/')}
        />
      </TeamImageWrapper>
      <BottomSection>
        <ContentWrapper>
          <Column>
            <ColumnTitle>Unlock Your startup Potential</ColumnTitle>
            <Button onClick={handlePitchClick}>PITCH TO US</Button>
          </Column>
          <Column>
            <ColumnTitle>Connect</ColumnTitle>
            <ContactInfo>
              <a style={{textTransform:"lowercase",textDecoration:"none",color: 'rgba(13,74,100,1)' }}href="mailto:hi@rdv.studio">hi@rdv.studio</a>
              <br />
              <strong>301.306.5102</strong>
            
            </ContactInfo>
            <SocialLink
              href="https://www.linkedin.com/company/radiant-digital-ventures-studio/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <SocialIcon loading="lazy" src="./Footer Content.png" alt="LinkedIn" />
            </SocialLink>
          </Column>
          <Column>
            <ColumnTitle>Where to Find Us</ColumnTitle>
            <ContactInfo>
              8229 Boone Blvd, Suite 325
              <br />
              Vienna, VA 22182
              <br />
              <a
                href={googleMapsUrl}
                style={{ textDecoration: 'underline', color: 'rgba(13,74,100,1)' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                View on Map
              </a>
            </ContactInfo>
          </Column>
        </ContentWrapper>
        <FooterStrip />
      </BottomSection>
    </FooterWrapper>
  );
}

export default Footer;
