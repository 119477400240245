// import React from 'react';
// import styled from 'styled-components';

// const SectionWrapper = styled.section`
//   background-color: rgba(255, 255, 255, 1);
//   position: relative;
//   z-index: 0;
//   display: flex;
//   width: 100%;
//   align-items: flex-start;
//   gap: 40px 100px;
//   color: rgba(27, 94, 91, 1);
//   justify-content: start;
//   @media (max-width: 991px) {
//     max-width: 100%;
    
//   }
// `;

// const BackgroundImage = styled.img`
//   aspect-ratio: 0.66;
//   object-fit: contain;
//   object-position: center;
//   width: 765px;
//   z-index: 0;
//   min-width: 240px;
//   margin: auto 0;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const ContentWrapper = styled.div`
//   align-self: start;
//   position: absolute;
//   z-index: 0;
//   display: flex;
//   min-width: 240px;
//   align-items: center;
//   justify-content: start;
//   right: 29px;
//   top: 159px;
//   width: 826px;
//   @media (max-width: 991px) {
//     max-width: 100%;
//      top: 118px;
//   }
// `;

// const ContentBackground = styled.div`
//   background-color: rgba(13, 74, 100, 1);
//   z-index: 0;
//   display: flex;
//   min-width: 240px;
//   height: 877px;
//   gap: 10px;
//   width: 826px;
//   margin: auto 0;
//   @media (max-width: 991px) {
  
//                margin-left: 61px;
//         max-width: 573px;
      
    
   
  
//   }
// `;

// const Content = styled.div`
//   background-color: rgba(240, 241, 241, 1);
//   align-self: start;
//   position: absolute;
//   z-index: 0;
//   display: flex;
//   min-width: 240px;
 
//   align-items: center;
//   gap: 10px;
//   justify-content: start;
//   left: -20px;
//   top: -20px;
 
 
//   padding: 93px 150px;
//   @media (max-width: 991px) {
  
//         left: 50px;
//         height: 877px;
       
      
       
//         overflow: scroll;
//         padding: 0px 20px;
//         scrollbar-width: none;
   
//   }
//           @media (max-width: 430px) {
  
//         left: 50px;
//         height: 877px;
       
//       max-width: 318px;
//         height: 480px;
//         overflow: scroll;
//         padding: 0px 20px;
//         scrollbar-width: none;
   
//   }
//             @media (max-width: 389px) {
  
//         left: 50px;
//         height: 877px;
       
//       max-width: 275px;
//         height: 480px;
//         overflow: scroll;
//         padding: 0px 20px;
//         scrollbar-width: none;
   
//   }
// `;

// const TextContent = styled.div`
//   align-self: stretch;
//   display: flex;
//   min-width: 240px;
//   width: 525px;
//   flex-direction: column;
//   justify-content: start;
//   margin: auto 0;
// `;

// const Title = styled.h2`
//   text-transform: uppercase;
//   font: 700 34px/62px Open Sans, sans-serif;
// `;

// const Description = styled.p`

//   margin-top: 32px;
//   font: 400 24px/40px Inter, sans-serif;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// function FocusAreaSection() {
//   return (
//     <SectionWrapper id="focus">
//       <BackgroundImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/52961f9501b9a36897346922fca9e7456c37dfcd5a90354b6a66cecd8a2f078e?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
//       <ContentWrapper>
//         <ContentBackground />
//         <Content>
//           <TextContent>
//             <Title>Focus AREA</Title>
//             <Description>
//               RDV and RDS bring deep expertise in federal and commercial digital transformation, serving clients across prominent sectors. We support startups applying innovative technologies to address major challenges in cybersecurity, sensors, cloud/data infrastructure, and consumer experience (CX). We see significant opportunities for AI, automation, and novel data management tools to create dramatic customer impact. RDV focuses on pre-revenue startups, typically founder self-funded or with initial seed investments, becoming the first institutional investor.
//             </Description>
//           </TextContent>
//         </Content>
//       </ContentWrapper>
//     </SectionWrapper>
//   );
// }

// export default FocusAreaSection;

// import React from "react";
// import styled from "styled-components";
// import ContentSection from "./ContentSection";

// const FocusAreaSection = () => {
//   return (
//     <StyledFocusArea>
//       <ContentWrapper>
//         <StyledImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669" alt="Focus Area Illustration" />
//         <ContentSection />
//       </ContentWrapper>
//     </StyledFocusArea>
//   );
// };

// const StyledFocusArea = styled.section`
//   background-color: #fff;
//   display: flex;
//   flex-direction: column;
//   overflow: hidden;
//   color: #0d4a64;
// `;

// const ContentWrapper = styled.div`
//   display: flex;
//   width: 100%;
//   align-items: center;
//   justify-content: space-between;

//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const StyledImage = styled.img`
//   aspect-ratio: 0.9;
//   object-fit: contain;
//   object-position: center;
//   width: 50%;
//   align-self: stretch;
//   min-width: 240px;
//   margin: auto 0;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// export default FocusAreaSection;

import * as React from "react";
import styled from "styled-components";

export default function FocusAreaSection() {
  return (
    <FocusArea id="focus">
      <Div>
        <Img
          loading="lazy"
          srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/897216920354c4d2910f034340f296aa234f06d30a3baed016641400aa038425?placeholderIfAbsent=true&apiKey=aafae26bdbcc40e0829623d48788b669"
        />
        <Div2>
          <Div3>
            <Div4>
              <FocusArea2>Focus AREA</FocusArea2>
              <Content>
                RDV and RDS bring deep expertise in federal and commercial
                digital transformation, serving clients across prominent
                sectors. We support startups applying innovative technologies to
                address major challenges in cybersecurity, sensors, cloud/data
                infrastructure, and consumer experience (CX). We see significant
                opportunities for AI, automation, and novel data management
                tools to create dramatic customer impact. RDV focuses on
                pre-revenue startups, typically founder self-funded or with
                initial seed investments, becoming the first institutional
                investor.
              </Content>
            </Div4>
          </Div3>
        </Div2>
      </Div>
    </FocusArea>
  );
}

const FocusArea = styled.div`
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: rgba(13, 74, 100, 1);
`;

const Div = styled.div`
  display: flex;
  width: 100%;

  align-items: stretch; /* Stretch both items to the height of the parent */
  justify-content: space-between;

  @media (max-width: 991px) {

  }
`;

const Img = styled.img`
  width: 50%;
  object-fit: cover; /* Cover the area without losing aspect ratio */
  @media (max-width: 991px) {
    width: 50%;
    height: auto; /* Allow image to resize properly on smaller screens */
  }
`;

const Div2 = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    width: 50%;
  }
`;

const Div3 = styled.div`
  background-color: rgba(240, 241, 241, 1);
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0px 95px;
  border: 1px solid rgba(27, 94, 91, 1);
  height: 100%;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const Div4 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const FocusArea2 = styled.div`
  text-transform: uppercase;
  font: 700 34px/62px Open Sans, sans-serif;
`;

const Content = styled.div`
  margin-top: 32px;
  font: 400 24px/40px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
