// // import React, { useState } from 'react';
// // import styled from 'styled-components';
// // import { Formik, Form, Field, ErrorMessage } from 'formik';
// // import * as Yup from 'yup';
// // import PhoneInput from 'react-phone-input-2';
// // import 'react-phone-input-2/lib/style.css';
// // import { toast, ToastContainer } from 'react-toastify';
// // import 'react-toastify/dist/ReactToastify.css';
// // const CustomPhoneInput = styled(PhoneInput)`
// //   .form-control {
// //     border-radius: 12px;
// //     border: 1px solid #d9d9d9;
// //     background-color: #f1f2f2;
// //     margin-top: 16px;
// //     min-height: 48px;
// //     font-size: 16px;
// //     color: #76797a;
// //     font-weight: 400;
// //     width: 100%;

// //     @media (max-width: 991px) {
// //       padding: 14px 20px;
// //       width: 80%;
// //     }
// //   }
// // `;

// // const FormWrapper = styled.section`
// //   display: flex;
// //   width: 100%;
// //   flex-direction: column;
// //   justify-content: flex-start;
// //   @media (max-width: 991px) {
// //     max-width: 100%;
// //   }
// // `;

// // const FormContainer = styled.div`
// //   justify-content: center;
// //   align-items: center;
// //   border-bottom: 1px solid #f1f2f2;
// //   background-color: #fcfcfd;
// //   display: flex;
// //   width: 100%;
// //   flex-direction: column;
// //   padding: 65px 0;
// //   @media (max-width: 991px) {
// //     max-width: 100%;
// //   }
// // `;



// // const FormHeader = styled.div`
// //   display: flex;
// //   width: 100%;
// //   max-width: 837px;
// //   flex-direction: column;
// //   justify-content: flex-start;
// //   @media (max-width: 991px) {
// //     max-width: 100%;
// //   }
// // `;

// // const HeaderContent = styled.div`
// //   display: flex;
// //   width: 100%;
// //   align-items: center;
// //   gap: 40px 100px;
// //   justify-content: space-between;
// //   flex-wrap: wrap;
// // `;

// // const FormTitle = styled.h2`
// //   color: #313638;
// //   align-self: stretch;
// //   margin: auto 0;
// //   font: 600 40px Poppins, sans-serif;
// // `;

// // const RequiredText = styled.span`
// //   color: #e00;
// //   align-self: stretch;
// //   margin: auto 0;
// //   font: 400 16px/1.3 Inter, sans-serif;
// // `;

// // const FormDescription = styled.p`
// //   color: #313638;
// //   font: 300 16px Poppins, sans-serif;
// //   @media (max-width: 991px) {
// //     max-width: 100%;
// //   }
// // `;

// // const FormSection = styled.div`
// //   display: flex;
// //   margin-top: 24px;
// //   width: 100%;
// //   max-width: 837px;
// //   flex-direction: column;
// //   font-family: Inter, sans-serif;
// //   justify-content: flex-start;
// //   @media (max-width: 991px) {
// //     max-width: 100%;
// //   }
// // `;

// // const InputGroup = styled.div`
// //   display: flex;
// //   width: 100%;
// //   flex-direction: column;
// //   justify-content: flex-start;
// //   margin-top: ${props => props.marginTop || '0'};
// //   @media (max-width: 991px) {
// //     max-width: 100%;
// //   }
// // `;

// // const Label = styled.label`
// //   color: #0d4a64;
// //   font-size: 20px;
// //   font-weight: 500;
// //   line-height: 1.3;
// // `;

// // const Input = styled.input`
// //   border-radius: 12px;
// //   border: 1px solid #d9d9d9;
// //   background-color: #f1f2f2;
// //   margin-top: 16px;
// //   min-height: 48px;
// //   max-width: 837px;
// //   font-family: Inter, sans-serif;

// //   font-size: 16px;
// //   color: #76797a;
// //   font-weight: 400;
// //       padding-left: 24px;
  
// //   @media (max-width: 991px) {
// //     padding: 14px 20px;
// //             width: 80%;
// //   }
// // `;

// // const TextArea = styled.textarea`
// //   border-radius: 12px;
// //   border: 1px solid #d9d9d9;
// //   background-color: #f1f2f2;
// //   margin-top: 16px;
// //   min-height: 202px;
// //     width: 813px;
// //     padding-left: 24px;
// //      padding-top: 24px;
// //     font-family: Inter, sans-serif;
 
// //   font-size: 17px;
// //   color: #76797a;
// //   font-weight: 400;

// //   resize: vertical;
// //   @media (max-width: 991px) {
// //     padding: 20px;
// //             width: 80%;
// //   }
// // `;

// // const FileUpload = styled.div`
// //   display: flex;
// //   align-items: center;
// //   margin-top: 16px;
// //   width: 100%;
// //   max-width: 100%;
// //   flex-direction: row; /* Changed to row to align items horizontally */
// //   gap: 16px; /* Space between button and file name */
// // `;

// // const FileInput = styled.input`
// //   display: none;
// // `;

// // const FileLabel = styled.label`
// //   display: flex;
// //   align-items: center;
// //   justify-content: center;
// //   border-radius: 12px;
// //   border: 1px solid #d9d9d9;
// //   background-color: #f1f2f2;
// //   min-height: 48px;
// //   font-size: 16px;
// //   color: #313638;
// //   font-weight: 400;
 
// //   cursor: pointer;
// //   @media (max-width: 991px) {
// //     padding: 12px 20px;
// //   }
// // `;

// // const CheckboxGroup = styled.div`
// //   display: flex;
// //   align-items: center;
// //   gap: 12px;
// //   margin-top: 32px;
// // `;

// // const Checkbox = styled.input`
// //   width: 26px;
// //   height: 26px;
// // `;

// // const CheckboxLabel = styled.label`
// //   color: #000;
// //   font: 300 16px Poppins, sans-serif;
// // `;

// // const SubmitButton = styled.button`
// //   align-self: flex-start;
// //   border-radius: 32px;
// //   background-color: #91c46b;
// //   color: #fff;
// //   font-size: 18px;
// //   font-weight: 600;
// //   padding: 21px 48px;
// //   border: none;
// //   cursor: pointer;
// //   margin-top: 40px;
// //   @media (max-width: 991px) {
// //     padding: 21px 20px;
// //   }
    
// // `;


// // const FileName = styled.span`
// //   flex: 1; /* Make the file name span the remaining space */
// //   color: #313638;
// //   font-size: 16px;
// //   line-height: 48px; /* Align vertically with the button */
// // `;

// // const IconImage = styled.img`
// //   width: 18px; /* Adjust size as needed */
// //   height: 19px; /* Adjust size as needed */
// // `;
// // const validationSchema = Yup.object({
// //   startupName: Yup.string().required('Required'),
// //   address: Yup.string().required('Required'),
// //   phone: Yup.string(),
// //   email: Yup.string().email('Invalid email address').required('Required'),
// //   founderName: Yup.string().required('Required'),
// //   description: Yup.string().required('Required'),
// //   agreeTerms: Yup.bool().oneOf([true], 'You must agree to the terms and conditions').required('Required'),
// // });
// // const PitchForm = () => {
// //   const [fileName, setFileName] = useState('');
// //   return (
// //   <Formik
// //     initialValues={{
// //       startupName: '',
// //       address: '',
// //       phone: '',
// //       email: '',
// //       founderName: '',
// //       description: '',
// //       fileUpload: null,
// //       agreeTerms: false,
// //     }}
// //     validationSchema={validationSchema}
// //     onSubmit={(values) => {
// //       console.log('Form data', values);
// //     }}
// //   >
// //     {({ setFieldValue }) => (
// //       <FormWrapper>
// //         <FormContainer>
// //           <Form>
// //             <FormHeader>
// //               <HeaderContent>
// //                 <FormTitle>Pitch to us</FormTitle>
// //                 <RequiredText>*Required</RequiredText>
// //               </HeaderContent>
// //               <FormDescription>
// //                 Please provide your contact information below and attach your business plan.
// //               </FormDescription>
// //             </FormHeader>
// //             <FormSection>
// //               <InputGroup>
// //                 <Label htmlFor="startupName">Startup Name</Label>
// //                 <Input type="text" id="startupName" name="startupName" placeholder="Startup Name*" />
// //                 <ErrorMessage name="startupName" component="div" />
// //               </InputGroup>
// //               <InputGroup marginTop="32px">
// //                 <Label htmlFor="address">Address</Label>
// //                 <Input type="text" id="address" name="address" placeholder="Address*" />
// //                 <ErrorMessage name="address" component="div" />
// //               </InputGroup>
// //               <InputGroup marginTop="32px">
// //                 <Label htmlFor="phone">Phone</Label>
// //                 <CustomPhoneInput
// //                 country={'us'}
// //                 onChange={(phone) => setFieldValue('phone', phone)}
// //               />
// //               <ErrorMessage name="phone" component="div" />
// //               </InputGroup>
// //               <InputGroup marginTop="32px">
// //                 <Label htmlFor="email">Email</Label>
// //                 <Input type="email" id="email" name="email" placeholder="Email*" />
// //                 <ErrorMessage name="email" component="div" />
// //               </InputGroup>
// //               <InputGroup marginTop="32px">
// //                 <Label htmlFor="founderName">Founder Name</Label>
// //                 <Input type="text" id="founderName" name="founderName" placeholder="Founder Name*" />
// //                 <ErrorMessage name="founderName" component="div" />
// //               </InputGroup>
// //               <InputGroup marginTop="32px">
// //                 <Label htmlFor="description">Brief description of your product/concept/innovation</Label>
// //                 <TextArea as="textarea" id="description" name="description" placeholder="Brief description of your product/concept/innovation*" />
// //                 <ErrorMessage name="description" component="div" />
// //               </InputGroup>

// //               <InputGroup marginTop="32px">
// //               <Label htmlFor="fileUpload">Upload your concept presentation, business plan/canvas:</Label>
// //               <FileUpload>
// //                 <FileInput
// //                   id="fileUpload"
// //                   name="fileUpload"
// //                   type="file"
// //                   onChange={(event) => {
// //                     const file = event.currentTarget.files[0];
// //                     setFieldValue('fileUpload', file);
// //                     setFileName(file ? file.name : '');
// //                   }}
// //                 />
// //                 <FileLabel htmlFor="fileUpload">
// //                   <IconImage src="./Icon.png" alt="Upload Icon" />
// //                   Choose File
// //                 </FileLabel>
// //                 <FileName>{fileName}</FileName>
// //               </FileUpload>
// //             </InputGroup>
// //             <CheckboxGroup>
// //               <Checkbox type="checkbox" id="agreeTerms" name="agreeTerms" />
// //               <CheckboxLabel htmlFor="agreeTerms">I agree to the terms and conditions</CheckboxLabel>
// //               <ErrorMessage name="agreeTerms" component="div" />
// //             </CheckboxGroup>
// //             </FormSection>
// //             <SubmitButton type="submit">Submit</SubmitButton>
// //           </Form>
// //         </FormContainer>
// //       </FormWrapper>
// //     )}
// //   </Formik>
// // );
// // };

// // export default PitchForm;




// import React, { useState } from 'react';
// import styled from 'styled-components';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

// const CustomPhoneInput = styled(PhoneInput)`
//   .form-control {
//     border-radius: 12px;
//     border: 1px solid #d9d9d9;
//     background-color: #f1f2f2;
//     margin-top: 16px;
//     min-height: 48px;
//     font-size: 16px;
//     color: #76797a;
//     font-weight: 400;
//     width: 100%;

//     @media (max-width: 991px) {
//       padding: 14px 20px;
//       width: 80%;
//     }
//   }
// `;

// const FormWrapper = styled.section`
//   display: flex;
//   width: 100%;
//   flex-direction: column;
//   justify-content: flex-start;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const FormContainer = styled.div`
//   justify-content: center;
//   align-items: center;
//   border-bottom: 1px solid #f1f2f2;
//   background-color: #fcfcfd;
//   display: flex;
//   width: 100%;
//   flex-direction: column;
//   padding: 65px 0;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;



// const FormHeader = styled.div`
//   display: flex;
//   width: 100%;
//   max-width: 837px;
//   flex-direction: column;
//   justify-content: flex-start;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const HeaderContent = styled.div`
//   display: flex;
//   width: 100%;
//   align-items: center;
//   gap: 40px 100px;
//   justify-content: space-between;
//   flex-wrap: wrap;
// `;

// const FormTitle = styled.h2`
//   color: #313638;
//   align-self: stretch;
//   margin: auto 0;
//   font: 600 40px Poppins, sans-serif;
// `;

// const RequiredText = styled.span`
//   color: #e00;
//   align-self: stretch;
//   margin: auto 0;
//   font: 400 16px/1.3 Inter, sans-serif;
// `;

// const FormDescription = styled.p`
//   color: #313638;
//   font: 300 16px Poppins, sans-serif;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const FormSection = styled.div`
//   display: flex;
//   margin-top: 24px;
//   width: 100%;
//   max-width: 837px;
//   flex-direction: column;
//   font-family: Inter, sans-serif;
//   justify-content: flex-start;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const InputGroup = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: column;
//   justify-content: flex-start;
//   margin-top: ${props => props.marginTop || '0'};
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const Label = styled.label`
//   color: #0d4a64;
//   font-size: 20px;
//   font-weight: 500;
//   line-height: 1.3;
// `;

// const Input = styled.input`
//   border-radius: 12px;
//   border: 1px solid #d9d9d9;
//   background-color: #f1f2f2;
//   margin-top: 16px;
//   min-height: 48px;
//   max-width: 837px;
//   font-family: Inter, sans-serif;

//   font-size: 16px;
//   color: #76797a;
//   font-weight: 400;
//       padding-left: 24px;
  
//   @media (max-width: 991px) {
//     padding: 14px 20px;
//             width: 80%;
//   }
// `;

// const TextArea = styled.textarea`
//   border-radius: 12px;
//   border: 1px solid #d9d9d9;
//   background-color: #f1f2f2;
//   margin-top: 16px;
//   min-height: 202px;
//     width: 813px;
//     padding-left: 24px;
//      padding-top: 24px;
//     font-family: Inter, sans-serif;
 
//   font-size: 17px;
//   color: #76797a;
//   font-weight: 400;

//   resize: vertical;
//   @media (max-width: 991px) {
//     padding: 20px;
//             width: 80%;
//   }
// `;

// const FileUpload = styled.div`
//   display: flex;
//   align-items: center;
//   margin-top: 16px;
//   width: 100%;
//   max-width: 100%;
//   flex-direction: row; /* Changed to row to align items horizontally */
//   gap: 16px; /* Space between button and file name */
// `;

// const FileInput = styled.input`
//   display: none;
// `;

// const FileLabel = styled.label`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border-radius: 12px;
//   border: 1px solid #d9d9d9;
//   background-color: #f1f2f2;
//   min-height: 48px;
//   font-size: 16px;
//   color: #313638;
//   font-weight: 400;
 
//   cursor: pointer;
//   @media (max-width: 991px) {
//     padding: 12px 20px;
//   }
// `;

// const CheckboxGroup = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 12px;
//   margin-top: 32px;
// `;

// const Checkbox = styled.input`
//   width: 26px;
//   height: 26px;
// `;

// const CheckboxLabel = styled.label`
//   color: #000;
//   font: 300 16px Poppins, sans-serif;
// `;

// const SubmitButton = styled.button`
//   align-self: flex-start;
//   border-radius: 32px;
//   background-color: #91c46b;
//   color: #fff;
//   font-size: 18px;
//   font-weight: 600;
//   padding: 21px 48px;
//   border: none;
//   cursor: pointer;
//   margin-top: 40px;
//   @media (max-width: 991px) {
//     padding: 21px 20px;
//   }
    
// `;


// const FileName = styled.span`
//   flex: 1; /* Make the file name span the remaining space */
//   color: #313638;
//   font-size: 16px;
//   line-height: 48px; /* Align vertically with the button */
// `;

// const IconImage = styled.img`
//   width: 18px; /* Adjust size as needed */
//   height: 19px; /* Adjust size as needed */
// `;
// const validationSchema = Yup.object({
//   startupName: Yup.string().required('Required'),
//   address: Yup.string().required('Required'),
//   phone: Yup.string().required('Required'),
//   email: Yup.string().email('Invalid email address').required('Required'),
//   founderName: Yup.string().required('Required'),
//   description: Yup.string().required('Required'),
//   agreeTerms: Yup.bool().oneOf([true], 'You must agree to the terms and conditions').required('Required'),
// });

// const PitchForm = () => {
//   const [fileName, setFileName] = useState('');
  
//   const handleSubmit = (values, { resetForm }) => {
//     // Simulate form submission
//     setTimeout(() => {
//       toast.success('Form submitted successfully!');
//       resetForm(); // Clear form fields
//     }, 1000);
//   };

//   return (
//     <>
//       <ToastContainer />
//       <Formik
//         initialValues={{
//           startupName: '',
//           address: '',
//           phone: '',
//           email: '',
//           founderName: '',
//           description: '',
//           fileUpload: null,
//           agreeTerms: false,
//         }}
//         validationSchema={validationSchema}
//         onSubmit={(values, { resetForm }) => {
//           // You can replace the below handleSubmit with actual form submission logic
//           handleSubmit(values, { resetForm });
//         }}
//       >
//         {({ setFieldValue }) => (
//           <FormWrapper>
//             <FormContainer>
//               <Form>
//                 <FormHeader>
//                   <HeaderContent>
//                     <FormTitle>Pitch to us</FormTitle>
//                     <RequiredText>*Required</RequiredText>
//                   </HeaderContent>
//                   <FormDescription>
//                     Please provide your contact information below and attach your business plan.
//                   </FormDescription>
//                 </FormHeader>
//                 <FormSection>
//                   <InputGroup>
//                     <Label htmlFor="startupName">Startup Name</Label>
//                     <Input type="text" id="startupName" name="startupName" placeholder="Startup Name*" />
//                     <ErrorMessage name="startupName" component="div" />
//                   </InputGroup>
//                   <InputGroup marginTop="32px">
//                     <Label htmlFor="address">Address</Label>
//                     <Input type="text" id="address" name="address" placeholder="Address*" />
//                     <ErrorMessage name="address" component="div" />
//                   </InputGroup>
//                   <InputGroup marginTop="32px">
//                     <Label htmlFor="phone">Phone</Label>
//                     <CustomPhoneInput
//                       country={'us'}
//                       onChange={(phone) => setFieldValue('phone', phone)}
//                     />
//                     <ErrorMessage name="phone" component="div" />
//                   </InputGroup>
//                   <InputGroup marginTop="32px">
//                     <Label htmlFor="email">Email</Label>
//                     <Input type="email" id="email" name="email" placeholder="Email*" />
//                     <ErrorMessage name="email" component="div" />
//                   </InputGroup>
//                   <InputGroup marginTop="32px">
//                     <Label htmlFor="founderName">Founder Name</Label>
//                     <Input type="text" id="founderName" name="founderName" placeholder="Founder Name*" />
//                     <ErrorMessage name="founderName" component="div" />
//                   </InputGroup>
//                   <InputGroup marginTop="32px">
//                     <Label htmlFor="description">Brief description of your product/concept/innovation</Label>
//                     <TextArea as="textarea" id="description" name="description" placeholder="Brief description of your product/concept/innovation*" />
//                     <ErrorMessage name="description" component="div" />
//                   </InputGroup>

//                   <InputGroup marginTop="32px">
//                     <Label htmlFor="fileUpload">Upload your concept presentation, business plan/canvas:</Label>
//                     <FileUpload>
//                       <FileInput
//                         id="fileUpload"
//                         name="fileUpload"
//                         type="file"
//                         onChange={(event) => {
//                           const file = event.currentTarget.files[0];
//                           setFieldValue('fileUpload', file);
//                           setFileName(file ? file.name : '');
//                         }}
//                       />
//                       <FileLabel htmlFor="fileUpload">
//                         <IconImage src="./Icon.png" alt="Upload Icon" />
//                         Choose File
//                       </FileLabel>
//                       <FileName>{fileName}</FileName>
//                     </FileUpload>
//                   </InputGroup>
//                   {/*<CheckboxGroup>
//                     <Checkbox type="checkbox" id="agreeTerms" name="agreeTerms" />
//                     <CheckboxLabel htmlFor="agreeTerms">I agree to the terms and conditions</CheckboxLabel>
//                     <ErrorMessage name="agreeTerms" component="div" />
//                   </CheckboxGroup>*/}
//                 </FormSection>
//                 <SubmitButton type="submit">Submit</SubmitButton>
//               </Form>
//             </FormContainer>
//           </FormWrapper>
//         )}
//       </Formik>
//     </>
//   );
// };

// export default PitchForm;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomPhoneInput = styled(PhoneInput)`
  .form-control {
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    background-color: #f1f2f2;
    margin-top: 16px;
    min-height: 48px;
    font-size: 16px;
    color: #76797a;
    font-weight: 400;
    width: 100%;
    

    @media (max-width: 991px) {
      padding: 14px 20px;
      width: 80%;
    }
  }
`;

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-top: 8px;
`;

const FormWrapper = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormContainer = styled.div`
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #f1f2f2;
  background-color: #fcfcfd;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 65px 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormHeader = styled.div`
  display: flex;
  width: 100%;
  max-width: 837px;
  flex-direction: column;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 100px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FormTitle = styled.h2`
  color: #313638;
  align-self: stretch;
  margin: auto 0;
  font: 600 40px Poppins, sans-serif;
`;

const RequiredText = styled.span`
  color: #e00;
  align-self: stretch;
  margin: auto 0;
  font: 400 16px/1.3 Inter, sans-serif;
`;

const FormDescription = styled.p`
  color: #313638;
  font: 300 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const FormSection = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
   gap:24px;
  max-width: 837px;
  flex-direction: column;
  font-family: Inter, sans-serif;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const InputGroup = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Label = styled.label`
  color: #0d4a64;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
`;

const Input = styled.input`
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background-color: #f1f2f2;
  margin-top: 16px;
  min-height: 48px;
  max-width: 837px;
  font-family: Inter, sans-serif;

  font-size: 16px;
  color: #76797a;
  font-weight: 400;
  padding-left: 24px;
  
  @media (max-width: 991px) {
    padding: 14px 20px;
    width: 80%;
  }
`;

const TextArea = styled.textarea`
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background-color: #f1f2f2;
  margin-top: 16px;
  min-height: 202px;
  width: 813px;
  padding-left: 24px;
  padding-top: 24px;
  font-family: Inter, sans-serif;
 
  font-size: 17px;
  color: #76797a;
  font-weight: 400;

  resize: vertical;
  @media (max-width: 991px) {
    padding: 20px;
    width: 80%;
  }
`;

const FileUpload = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 100%;
  max-width: 100%;
  flex-direction: row;
  gap: 16px;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: 1px solid #d9d9d9;
  background-color: #f1f2f2;
  min-height: 48px;
  font-size: 16px;
  color: #313638;
  font-weight: 400;
  cursor: pointer;
      padding-right: 25px;
    padding-left: 15px;

  @media (max-width: 991px) {
  padding-right: 25px;
    padding-left: 15px;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 32px;
`;

const Checkbox = styled.input`
  width: 26px;
  height: 26px;
`;

const CheckboxLabel = styled.label`
  color: #000;
  font: 300 16px Poppins, sans-serif;
`;

const SubmitButton = styled.button`
  align-self: flex-start;
  border-radius: 32px;
  background-color: #91c46b;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 21px 48px;
  border: none;
  cursor: pointer;
  margin-top: 40px;
  @media (max-width: 991px) {
    padding: 21px 20px;
  }
`;

const FileName = styled.span`
  flex: 1;
  color: #313638;
  font-size: 16px;
  line-height: 48px;
`;

const IconImage = styled.img`
  width: 18px;
  height: 19px;
`;

const validationSchema = Yup.object({
  startupName: Yup.string().required('Startup Name is required'),
  address: Yup.string().required('Address is required'),
  phone: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  founderName: Yup.string().required('Founder Name is required'),
  description: Yup.string().required('Description is required'),

});

const PitchForm = () => {
  const [fileName, setFileName] = useState('');
  
  const handleSubmit = (values, { resetForm }) => {
    setTimeout(() => {
      toast.success('Thank you for your submission!');
      resetForm();
    }, 1000);
  };

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={{
          startupName: '',
          address: '',
          phone: '',
          email: '',
          founderName: '',
          description: '',
          fileUpload: null,
          agreeTerms: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, { resetForm });
        }}
      >
        {({ setFieldValue }) => (
          <FormWrapper>
            <FormContainer>
              <Form>
                <FormHeader>
                  <HeaderContent>
                    <FormTitle>Pitch to us</FormTitle>
                    <RequiredText>*Required</RequiredText>
                  </HeaderContent>
                  <FormDescription>
                    Please provide your contact information below and attach your business plan.
                  </FormDescription>
                </FormHeader>
                <FormSection>
                  <InputGroup>
                    <Label htmlFor="startupName">Startup Name</Label>
                    <Field as={Input} type="text" id="startupName" name="startupName" />
                    <ErrorMessage name="startupName" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="address">Address</Label>
                    <Field as={Input} type="text" id="address" name="address" />
                    <ErrorMessage name="address" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="phone">Phone</Label>
                    <CustomPhoneInput
                      country={'us'}
                      value={''}
                      onChange={phone => setFieldValue('phone', phone)}
                      
                    />
                    <ErrorMessage name="phone" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="email">Email</Label>
                    <Field as={Input} type="email" id="email" name="email" />
                    <ErrorMessage name="email" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="founderName">Founder Name</Label>
                    <Field as={Input} type="text" id="founderName" name="founderName" />
                    <ErrorMessage name="founderName" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="description">Brief Description</Label>
                    <Field as={TextArea} id="description" name="description" />
                    <ErrorMessage name="description" component={ErrorText} />
                  </InputGroup>

                  <InputGroup>
                    <Label htmlFor="fileUpload">Upload your business plan</Label>
                    <FileUpload>
                      <FileInput
                        id="fileUpload"
                        name="fileUpload"
                        type="file"
                        onChange={event => {
                          setFieldValue('fileUpload', event.target.files[0]);
                          setFileName(event.target.files[0].name);
                        }}
                      />
                     
                      <FileLabel htmlFor="fileUpload"> <IconImage src="./Icon.png" alt="Upload Icon" />Choose File</FileLabel>
                      <FileName>{fileName}</FileName>
                    </FileUpload>
                  </InputGroup>

               
                  
                  <SubmitButton type="submit">Submit</SubmitButton>
                </FormSection>
              </Form>
            </FormContainer>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};

export default PitchForm;

