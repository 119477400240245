

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


const SectionWrapper = styled.section`
  background-color: rgba(240, 241, 241, 1);
  z-index: 0;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px 41px;
  justify-content: end;

  @media (max-width: 600px) {
    max-width: 100%;
            flex-wrap: wrap;
  }
`;

const LeftColumn = styled.div`
  align-self: stretch;
  display: flex;
  min-height: 772px;
  flex-direction: column;
  justify-content: start;
  width: 150px;
  margin: auto 0;
`;

const Title = styled.h2`
  color: rgba(13, 74, 100, 1);
  text-transform: uppercase;
  font: 700 30px/48px Open Sans, sans-serif;
`;

const Infographics = styled.div`
  position: relative;
  display: flex;
 
  max-width: 100%;
  width: 134px;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;
const InfographicImage2 = styled.img`
    position: absolute;
    top: 138px;
    height: 276px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  z-index: 0;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
    
`;
const InfographicImage3 = styled.img`
border: 2px dashed #0394AC;

  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  z-index: 0;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
  
  }
`;
const InfographicImage4 = styled.img`
border: 2px dashed #0394AC;

  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  z-index: 0;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
   
  }
`;
const InfographicImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100%;
  z-index: 0;
  margin-top: ${props => props.marginTop || '0'};
  @media (max-width: 991px) {
   
  }
`;

const RightColumn = styled.div`
  background-color: rgba(13, 74, 100, 1);
  align-self: stretch;
  display: flex;
  min-width: 240px;
  min-height: 856px;
  flex-direction: column;
  align-items: start;
  font-family: Open Sans, sans-serif;
  color: rgba(240, 241, 241, 1);
  justify-content: center;
  width: 1049px;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  padding: 0 40px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const StepContent = styled.div`
  display: flex;

  max-width: 100%;
  flex-direction: column;
  justify-content: start;
 padding: 0px 55px;
`;

const StepTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0.24px;
  text-transform: uppercase;

  &::before {
    content: "${props => props.index + 1}.";
    margin-right: 8px;
  }
`;

const StepDescription = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.2px;
  margin-top: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Divider = styled.img`
  aspect-ratio: 333.33;
  object-fit: contain;
  object-position: center;
  width: 100%;
  align-self: stretch;
  margin-top: 36px;
`;
const HighlightedText = styled.span`
  color: #c7dd75;
  letter-spacing: 0.04px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;

function IntakeProcessSection() {
  const navigate = useNavigate();

  const handlePitchClick = () => {
    navigate('/form');
    window.scrollTo(0, 0); // Optional: Scroll to top when navigating
  };
  const steps = [
    {
      title: 'Get to know us',
     description: (
        <>
        Founders can set up an initial meeting by submitting their name, email, startup name, and mission statement. The call is expected to last around 30 minutes, during which we will assess their interest and discuss the next steps. {' '}
          <HighlightedText onClick={handlePitchClick}>Pitch to us</HighlightedText>
        </>
      ),
    },
    {
      title: 'Share your story',
      description: 'Please submit your business plan, pitch deck, and any financial information via email for RDV evaluation.',
    },
    {
      title: '"Inflection Point" Call',
      description: 'Provide a clear RDV perspective on initial company materials and potential investment and advisory options. If RDV expresses interest, we will begin an initial due diligence period of up to one month to explore investment terms in detail.',
    },
  ];

  return (
    <SectionWrapper id="process">
      <LeftColumn>
        <Title>
          Intake <br /> Process
        </Title>
        <Infographics>
          <InfographicImage2 loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/acc25b2c8824f4294e6e840fda277805e0a6b051b6570a88cb2c86086ad5b2e8?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
          <InfographicImage3 loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/45ea2f63c0034155fee948bbf434376a08d388343bd11c2bc89c2f03d97fb3a6?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt=""  />
          <InfographicImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/02ddf42a5946fb11f443a55d2b3e288d6ba6ed380ed175e35a7b156f248f069e?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" marginTop="74px" />
          <InfographicImage4 loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/37ee405db7eb604e282af0fdc93860b50272a3beaad85827371d66304fd6b116?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" marginTop="74px" />
        </Infographics>
      </LeftColumn>
      <RightColumn>
        <ContentContainer>
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <StepContent marginTop={index > 0 ? '36px' : '0'}>
                <StepTitle index={index}>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </StepContent>
              {index < steps.length - 1 && <Divider loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7bba2af04441945d502a8d4bc22503c95e33deb09aec24bcc53cc813d54b696e?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />}
            </React.Fragment>
          ))}
        </ContentContainer>
      </RightColumn>
    </SectionWrapper>
  );
}

export default IntakeProcessSection;
