import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.section`
  background-color: rgba(13, 74, 100, 1);
  z-index: 0;
  display: flex;

  flex-direction: column;
  justify-content: start;
  padding: 70px 126px 128px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px 100px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Header = styled.div`
  align-self: start;
  display: flex;
  align-items: center;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  color: rgba(240, 241, 241, 1);
  text-transform: uppercase;
  align-self: stretch;
  margin: auto 0;
  font: 700 34px/62px Open Sans, sans-serif;
`;

const StepContainer = styled.div`
  display: flex;
  margin-top: 48px;
  width: 100%;
  flex-direction: column;
  color: rgba(252, 247, 241, 1);

  justify-content: start;
  font: 700 24px/62px Open Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 13px;
  }
`;

const StepText = styled.p`
  align-self: stretch;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const StepIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 80px;
  align-self: stretch;
  margin: auto 0;
`;

const StepNumber = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 125px;
  align-self: stretch;
  margin: auto 0;
`;

const StepDescription = styled.p`
  display: none; /* Hidden by default */

  font-size: 16px;
  color: rgba(13, 74, 100, 1); /* Dark text color */
    width:100%;
    max-width:100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Step = styled.div`
  border-radius: 140px;
  display: flex;
  margin-top: ${(props) => props.marginTop || '0'};
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 30px 10px 90px;
  border: 2px solid rgba(255, 255, 255, 1);
  background-color: rgba(13, 74, 100, 1); /* Dark background color */
  color: rgba(252, 247, 241, 1); /* Light text color */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */

  &:hover {
    background-color: rgba(252, 247, 241, 1); /* Light background color on hover */
    color: rgba(13, 74, 100, 1); /* Dark text color on hover */
    border: 2px solid rgba(13, 74, 100, 1); /* Dark border on hover */
    border-radius: 6px;
   
    border-left: 10px solid #9BC779;

    ${StepText} {
      font-weight: bold; /* Bold text on hover */
      color: #1B5E5B;
      line-height: 62px;
    }

    ${StepIcon}, ${StepNumber} {
      filter: invert(1); /* Invert icon colors on hover */
    }
       ${StepIcon} {
      display: none; /* Hide the icon on hover */
    }

    /* Show additional content on hover */
    ${StepDescription} {
      display: block; /* Display the description on hover */
      color: #1B5E5B;
      font-size: 16px;
      text-transform: none;
      margin-top: -40px;
    }
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const StepContent = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 20px;
  justify-content: start;
  flex-wrap: wrap;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

function ApproachSection() {
  const steps = [
    {
      text: 'CONDUCT PROOF-OF-CONCEPT (POCs)',
      description: 'Test your startup idea in a small, controlled setting to validate market interest and gather initial customer feedback.',
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/0ca93e38824a686abc6608d357a229b9e83141583cc7673cead46a5d62770430?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66',
      numberSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/c378e56be3ffca9fc6873c5d6c4c18274c352cac4aa2b6680fe112c33c94fd24?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66'
    },
    {
      text: 'DEPLOY WORKING PILOTS CUSTOMER ENVIRONMENTS',
      description: "Launch pilot programs in real-world settings to prove your startup's operational viability and gain user acceptance.",
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/0ca93e38824a686abc6608d357a229b9e83141583cc7673cead46a5d62770430?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66',
      numberSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/59fa82e54d39d6c6178456784ed1d42d4ce4ba1d7eae1a764cf75ba9ca571737?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66'
    },
    {
      text: 'CREATE EARLY REVENUE SYSTEMS',
      description: 'Convert pilot successes into paying customers and explore opportunities to expand into related markets.',
      iconSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/0ca93e38824a686abc6608d357a229b9e83141583cc7673cead46a5d62770430?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66',
      numberSrc: 'https://cdn.builder.io/api/v1/image/assets/TEMP/a9d90b1b1cc986a5388e3db1eae0713be82e082104a75277c1a5fdab974b2c9f?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66'
    }
  ];
  
  return (
    <SectionWrapper>
      <Content>
        <Header>
          <Title>3-STEP APPROACH</Title>
        </Header>
        <StepContainer>
          {steps.map((step, index) => (
            <Step key={index} marginTop={index > 0 ? '70px' : '0'}>
              <StepContent>
                <StepIcon loading="lazy" src={step.iconSrc} alt="" />
                <StepText>{step.text}</StepText>
              </StepContent>
              <StepNumber loading="lazy" src={step.numberSrc} alt="" />
              <StepDescription>{step.description}</StepDescription>
            </Step>
          ))}
        </StepContainer>
      </Content>
    </SectionWrapper>
  );
}

export default ApproachSection;
