import React from 'react';

const Navigation = () => {
  return (
    <nav className="main-navigation">
      <a href="#vision" className="nav-link">Vision & Mission</a>
      <a href="#focus" className="nav-link">Focus Area</a>
      <a href="#process" className="nav-link">Intake Process</a>
      <a href="#team" className="nav-link">Our Team</a>
    </nav>
  );
};

export default Navigation;