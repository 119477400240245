import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../Components2/Header';
import VisionSection from './VisionSection';
import ApproachSection from './ApproachSection';
import FocusAreaSection from './FocusAreaSection';
import IntakeProcessSection from './IntakeProcessSection';
import Footer from './Footer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const LayoutWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

const BackgroundImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 720px;
  position: absolute;
  z-index: 1;
  min-height: 720px;
  max-width: 100%;
  align-items: start;
  gap: 10px;
  justify-content: start;
  right: 10px;
  top: 1024px;
  height: 720px;
  @media (max-width: 991px) {
    width: 348px;
    top: 1300px;
  }
     @media (max-width: 430px) {
    width: 290px;
    top: 1300px;
  }
`;

const ScrollButton = styled.button`
position: fixed;
bottom: 20px;
right: 20px;
width: 50px; /* Set width */
height: 50px; /* Set height */
padding: 0; /* Remove padding to keep it circular */
font-size: 16px;
border: none;
border-radius: 50%; /* Make it a circle */
background-color: #91c46b;
color: white; /* Add color to ensure text/icon is visible */
cursor: pointer;
z-index: 100;
display: flex; /* Center content */
align-items: center; /* Center content vertically */
justify-content: center; /* Center content horizontally */
transition: 'opacity 0.3s ease-in-out',
  
  &:hover {
    background-color: #0056b3;
  }
`;

function MainLayout() {

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <LayoutWrapper>
      <Header />
      <VisionSection />
      <ApproachSection />
      <FocusAreaSection />
      <IntakeProcessSection />
      <Footer />
      <BackgroundImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/46792f0efba704a03507a77eb50bcd82b9fc41a4424c463279b665a7b70f387d?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="" />
      <ScrollButton onClick={scrollToTop} style={{ opacity: isVisible ? '1' : '0', visibility: isVisible ? 'visible' : 'hidden' }}>
      <KeyboardArrowUpIcon style={{ height: '40px', width: '40px', color: 'white' }} />
      {/* <img style={{ height: '40px', width: '40px' }} src="./scroll.jpg" alt="Scroll to Top" /> */}
    </ScrollButton>
    </LayoutWrapper>
  );
}

export default MainLayout;
