import React from 'react';
import styled from 'styled-components';

const SectionWrapper = styled.section`
  background-color: rgba(240, 241, 241, 1);
  z-index: 0;
  display: flex;
  min-height: 720px;
 
  flex-direction: column;
  color: rgba(13, 74, 100, 1);
  justify-content: center;
  padding: 90px 200px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  text-transform: uppercase;
  font: 700 34px Open Sans, sans-serif;
`;

const Description = styled.p`
width:50%;
 
  font: 400 24px/40px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

function VisionSection() {
  return (
    <SectionWrapper id="vision">
      <Content>
        <Title>Vision and Mission</Title>
        <Description>
          RDV provides boutique investment and advisory support to early-stage startups, focusing on concept-to-exit journeys within 12-18 months. As part of Radiant Digital, we leverage our global expertise and industry relationships to deliver tailored guidance. Our team spans offices in the US, India, and Singapore, offering deep domain knowledge and successful case studies. Our unique 3-step approach accelerates early revenue, benefiting both founders and co-investors with journey-built advisory services.
        </Description>
      </Content>
    </SectionWrapper>
  );
}

export default VisionSection;