// import React from 'react';
// import styled from 'styled-components';
// import { useNavigate } from 'react-router-dom';

// const NavWrapper = styled.nav`
//      background-color: #0D4A64;
//   display: flex;

//   align-items: center;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   padding: 24px 80px;
//   @media (max-width: 991px) {
//     max-width: 100%;
//     padding: 0 20px;
//   }
// `;

// const Logo = styled.div`
//   align-self: stretch;
//   display: flex;
//   align-items: flex-start;
//   overflow: hidden;
//   justify-content: flex-start;
//   margin: auto 0;
//   padding: 1px 17px;
// `;

// const LogoImage = styled.img`
//   aspect-ratio: ${props => props.ratio};
//   object-fit: contain;
//   object-position: center;
//   width: ${props => props.width};
// `;

// const NavLinks = styled.div`
//   align-self: stretch;
//   display: flex;
//   min-width: 240px;
//   min-height: 44px;
//   align-items: center;
//   color: #f7f7f7;
//   text-transform: uppercase;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   width: 623px;
//   margin: auto 0;
//   font: 600 18px/1 'Open Sans', sans-serif;
//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

// const NavLink = styled.a`
//   align-self: stretch;
//   padding-bottom: 2px;
//   gap: 10px;
//   flex: 1;
//   margin: auto 0;
//   text-decoration: none;
//   color: inherit;
// `;

// const Navigation = () => {
//   const  navigate=useNavigate();
//   const handleNaviagtion = () => {
//     navigate('/');
//   }

// return(
//   <NavWrapper>
//     <Logo onClick={handleNaviagtion} style={{cursor:'pointer'}}>
//       <img src='/Logo Frame.png' alt="Logo 1" className="logo-image"/>
//       {/* <LogoImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/2ad38bdacd8267bcf00502170bf7daf43255602d22c8e7efe9e56b8d96680f43?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="Logo 1" ratio="2" width="8px" />
//       <LogoImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/89a9d7abc3f7b67a842c664d692f081f0cabae6f17b7fe3486603d161c5ece1e?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="Logo 2" ratio="1.75" width="7px" />
//       <LogoImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/662f58959e1551cdb52dd2de5911c19d3407f9435d4d38a41aa2237e0b0df8ce?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="Logo 3" ratio="0.9" width="18px" />
//       <LogoImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/675c27c142f551684dc656d617971fd42819935301c5ff6c8fdc78e2a3591be5?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="Logo 4" ratio="0.9" width="18px" />
//       <LogoImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/07b0e6296c52e62317ece6d7a3c35d307537ad54f292a553014ce4248e3b4a8b?placeholderIfAbsent=true&apiKey=a938513dc279413eaac5ce0f8c637f66" alt="Logo 5" ratio="0.95" width="19px" /> */}
//     </Logo>
//     <nav className="main-navigation">
//     <a href="#vision" className="nav-link">Vision & Mission</a>
//     <a href="#focus" className="nav-link">Focus Area</a>
//     <a href="#process" className="nav-link">Intake Process</a>
//     <a href="#team" className="nav-link">Our Team</a>
//   </nav>
//   </NavWrapper>
// );
// };

// export default Navigation;

import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
const NavWrapper = styled.nav`
     background-color: #0D4A64;
  display: flex;

  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 80px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 0 20px;
  }
`;

const Logo = styled.div`
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  justify-content: flex-start;
  margin: auto 0;
  padding: 1px 17px;
`;

const LogoImage = styled.img`
  aspect-ratio: ${props => props.ratio};
  object-fit: contain;
  object-position: center;
  width: ${props => props.width};
`;

const NavLinks = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  min-height: 44px;
  align-items: center;
  color: #f7f7f7;
  text-transform: uppercase;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 623px;
  margin: auto 0;
  font: 600 18px/1 'Open Sans', sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const NavLink = styled.a`
  align-self: stretch;
  padding-bottom: 2px;
  gap: 10px;
 
  margin: auto 0;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

const Navigation = () => {
  const navigate = useNavigate();

  const handleNavigation = (path, sectionId) => {
    navigate(path, { replace: true }); // Navigate to the path
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Slight delay to ensure the page is loaded before scrolling
  };

  return (
    <NavWrapper>
      <Logo onClick={() => handleNavigation('/', '')}>
        <LogoImage src='/Logo Frame.png' alt="Logo" ratio="1" width="80px" style={{ cursor: 'pointer' }}/>
      </Logo>
      <NavLinks className="main-navigation">
        <NavLink className="nav-link" onClick={() => handleNavigation('/', 'vision')}>Vision & Mission</NavLink>
        <NavLink  className="nav-link" onClick={() => handleNavigation('/', 'focus')}>Focus Area</NavLink>
        <NavLink className="nav-link" onClick={() => handleNavigation('/', 'process')}>Intake Process</NavLink>
        <NavLink className="nav-link" onClick={() => handleNavigation('/', 'team')}>Our Team</NavLink>
      </NavLinks>
    </NavWrapper>
  );
};

export default Navigation;

